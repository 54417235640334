import { useContext } from 'react'
import { AppContext } from 'library/context'
import { useRouter } from 'next/router'
import Link from 'components/link'

import { sdk } from 'library/configs/contentful'
import { cleanCircularReferences } from 'library/helpers/utilities'

import Wrapper from 'components/wrapper'
import RichText from 'components/rich-text'
import IndexList from 'components/index-list'

import { Select, Option } from '@contentful/f36-components'

const Index = props => {

  // Set up router
  const router = useRouter()

  // Get nav index
  const { navIndex } = useContext(AppContext)

  // Jump to page position
  const handleSelectChange = event => {
    if (event.target.value) {
      router.push(`/${event.target.value}`)
    }
  }

  // Build table of contents
  const tableOfContents = navIndex.rulesByType.map(entry => {
    return (
      <div key={entry.id} id={entry.slug} className={`toc-section ${entry.rules.length === 0 ? 'no-entries' : ''}`}>
        <h2>
          <Link
            href={`/${entry.slug}`}
            className="header-link"
          >
            {entry.name}
          </Link>
        </h2>
        <IndexList entries={entry.rules} id={entry.slug} organize={false} />
      </div>
    )
  })

  // Build select navigation
  const selectNav = (
    <Select
      width="full"
      onChange={handleSelectChange}
    >
      <Option value="">-- Quick Jump --</Option>
      {navIndex.rulesByType.map(entry => {
        return (
          <Option
            key={entry.id}
            value={`#${entry.slug}`}
          >
            {entry.name}
          </Option>
        )
      })}
      {process.env.NEXT_PUBLIC_ARMIES_ENABLED && <Option value="#army-index">Army Index</Option>}
    </Select>
  )

  // Remove main rulebook
  const mainRulebookSlug = process.env.NEXT_PUBLIC_MAIN_RULEBOOK_SLUG ? process.env.NEXT_PUBLIC_MAIN_RULEBOOK_SLUG : 'main-rulebook'
  const association = navIndex.rulesByArmy.filter(item => item.slug !== mainRulebookSlug)

  // Append army index
  let armyIndex
  if (process.env.NEXT_PUBLIC_ARMIES_ENABLED) {
    armyIndex = (
      <div id="army-index">
        <h2>
          <Link href="/sitemap/armies" className="header-link">
            Army Index
          </Link>
        </h2>
        <IndexList entries={association} id="association" />
      </div>
    )
  }

  // Wrapper title
  const tocLabel = process.env.NEXT_PUBLIC_TOC_LABEL ? process.env.NEXT_PUBLIC_TOC_LABEL : 'Table of Contents'

  return (
    <Wrapper
      title={tocLabel}
      description={`Welcome to the online rules index for ${process.env.NEXT_PUBLIC_SITE_TITLE}.`}
      notice={props.notice}
      entryId={props.id}
    >
      <RichText json={props.fields.body} />
      {selectNav}

      <div className="main-index">
        {tableOfContents}
        {armyIndex}
     </div>

    </Wrapper>
  )
}

export async function getStaticProps(context) {
  let data = {}
  let fields = {}
  let id = ''
  await sdk.getEntries({
    content_type: 'page',
    limit: 1,
    'fields.slug': 'home'
  })
  .then((res) => {
    data = res.items[0] ? cleanCircularReferences(res.items[0]) : data
    fields = data.fields ? data.fields : fields
    id = data.sys && data.sys.id ? data.sys.id : id
  })
  .catch(console.error)

  let notice = []
  await sdk.getEntries({
    content_type: 'notice',
    limit: 4,
    order: 'fields.order',
    'fields.active': true
  })
  .then((res) => {
    notice = res.items ? cleanCircularReferences(res.items) : notice
  })

  return {
    props: {
      fields: fields,
      id: id,
      notice: notice
    }
  }
}

export default Index